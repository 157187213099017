import { createApp } from 'vue'
import { createPinia } from 'pinia'
import 'virtual:svg-icons-register'
import Cookies from 'js-cookie'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import App from './App.vue'
import router from './router'
// import * as kiwi from '@/kiwi'
// import BuildInfo from '@/components/build_info.vue'

const app = createApp(App)

app.use(createPinia())
app.use(router)
// if (!kiwi.env.viteIsProd) {
//   app.component('BuildInfo', BuildInfo)
// }
app.use(ElementPlus, {
  locale: zhCn,
  // large、default、small
  size: Cookies.get('size') || 'default',
})

app.mount('#app')
